import * as _leaflet2 from "leaflet";

var _leaflet = "default" in _leaflet2 ? _leaflet2.default : _leaflet2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function (factory, window) {
  "use strict";

  exports = factory(_leaflet);

  if (typeof window !== "undefined" && window.L) {
    window.L.Ruler = factory(L);
  }
})(function (L) {
  "use strict";

  L.Control.Ruler = L.Control.extend({
    options: {
      position: "topright",
      circleMarker: {
        color: "red",
        radius: 2
      },
      lineStyle: {
        color: "red",
        dashArray: "1,6"
      },
      lengthUnit: {
        display: "km",
        decimal: 2,
        factor: null,
        label: "Distance:"
      },
      angleUnit: {
        display: "&deg;",
        decimal: 2,
        factor: null,
        label: "Bearing:"
      }
    },
    onAdd: function (map) {
      (this || _global)._map = map;
      (this || _global)._container = L.DomUtil.create("div", "leaflet-bar");

      (this || _global)._container.classList.add("leaflet-ruler");

      L.DomEvent.disableClickPropagation((this || _global)._container);
      L.DomEvent.on((this || _global)._container, "click", (this || _global)._toggleMeasure, this || _global);
      (this || _global)._choice = false;
      (this || _global)._defaultCursor = (this || _global)._map._container.style.cursor;
      (this || _global)._allLayers = L.layerGroup();
      return (this || _global)._container;
    },
    onRemove: function () {
      L.DomEvent.off((this || _global)._container, "click", (this || _global)._toggleMeasure, this || _global);
    },
    _toggleMeasure: function () {
      (this || _global)._choice = !(this || _global)._choice;
      (this || _global)._clickedLatLong = null;
      (this || _global)._clickedPoints = [];
      (this || _global)._totalLength = 0;

      if ((this || _global)._choice) {
        (this || _global)._map.doubleClickZoom.disable();

        L.DomEvent.on((this || _global)._map._container, "keydown", (this || _global)._escape, this || _global);
        L.DomEvent.on((this || _global)._map._container, "dblclick", (this || _global)._closePath, this || _global);

        (this || _global)._container.classList.add("leaflet-ruler-clicked");

        (this || _global)._clickCount = 0;
        (this || _global)._tempLine = L.featureGroup().addTo((this || _global)._allLayers);
        (this || _global)._tempPoint = L.featureGroup().addTo((this || _global)._allLayers);
        (this || _global)._pointLayer = L.featureGroup().addTo((this || _global)._allLayers);
        (this || _global)._polylineLayer = L.featureGroup().addTo((this || _global)._allLayers);

        (this || _global)._allLayers.addTo((this || _global)._map);

        (this || _global)._map._container.style.cursor = "crosshair";

        (this || _global)._map.on("click", (this || _global)._clicked, this || _global);

        (this || _global)._map.on("mousemove", (this || _global)._moving, this || _global);
      } else {
        (this || _global)._map.doubleClickZoom.enable();

        L.DomEvent.off((this || _global)._map._container, "keydown", (this || _global)._escape, this || _global);
        L.DomEvent.off((this || _global)._map._container, "dblclick", (this || _global)._closePath, this || _global);

        (this || _global)._container.classList.remove("leaflet-ruler-clicked");

        (this || _global)._map.removeLayer((this || _global)._allLayers);

        (this || _global)._allLayers = L.layerGroup();
        (this || _global)._map._container.style.cursor = (this || _global)._defaultCursor;

        (this || _global)._map.off("click", (this || _global)._clicked, this || _global);

        (this || _global)._map.off("mousemove", (this || _global)._moving, this || _global);
      }
    },
    _clicked: function (e) {
      (this || _global)._clickedLatLong = e.latlng;

      (this || _global)._clickedPoints.push((this || _global)._clickedLatLong);

      L.circleMarker((this || _global)._clickedLatLong, (this || _global).options.circleMarker).addTo((this || _global)._pointLayer);

      if ((this || _global)._clickCount > 0 && !e.latlng.equals((this || _global)._clickedPoints[(this || _global)._clickedPoints.length - 2])) {
        if ((this || _global)._movingLatLong) {
          L.polyline([(this || _global)._clickedPoints[(this || _global)._clickCount - 1], (this || _global)._movingLatLong], (this || _global).options.lineStyle).addTo((this || _global)._polylineLayer);
        }

        var text;
        (this || _global)._totalLength += (this || _global)._result.Distance;

        if ((this || _global)._clickCount > 1) {
          text = "<b>" + (this || _global).options.angleUnit.label + "</b>&nbsp;" + (this || _global)._result.Bearing.toFixed((this || _global).options.angleUnit.decimal) + "&nbsp;" + (this || _global).options.angleUnit.display + "<br><b>" + (this || _global).options.lengthUnit.label + "</b>&nbsp;" + (this || _global)._totalLength.toFixed((this || _global).options.lengthUnit.decimal) + "&nbsp;" + (this || _global).options.lengthUnit.display;
        } else {
          text = "<b>" + (this || _global).options.angleUnit.label + "</b>&nbsp;" + (this || _global)._result.Bearing.toFixed((this || _global).options.angleUnit.decimal) + "&nbsp;" + (this || _global).options.angleUnit.display + "<br><b>" + (this || _global).options.lengthUnit.label + "</b>&nbsp;" + (this || _global)._result.Distance.toFixed((this || _global).options.lengthUnit.decimal) + "&nbsp;" + (this || _global).options.lengthUnit.display;
        }

        L.circleMarker((this || _global)._clickedLatLong, (this || _global).options.circleMarker).bindTooltip(text, {
          permanent: true,
          className: "result-tooltip"
        }).addTo((this || _global)._pointLayer).openTooltip();
      }

      (this || _global)._clickCount++;
    },
    _moving: function (e) {
      if ((this || _global)._clickedLatLong) {
        L.DomEvent.off((this || _global)._container, "click", (this || _global)._toggleMeasure, this || _global);
        (this || _global)._movingLatLong = e.latlng;

        if ((this || _global)._tempLine) {
          (this || _global)._map.removeLayer((this || _global)._tempLine);

          (this || _global)._map.removeLayer((this || _global)._tempPoint);
        }

        var text;
        (this || _global)._addedLength = 0;
        (this || _global)._tempLine = L.featureGroup();
        (this || _global)._tempPoint = L.featureGroup();

        (this || _global)._tempLine.addTo((this || _global)._map);

        (this || _global)._tempPoint.addTo((this || _global)._map);

        this._calculateBearingAndDistance();

        (this || _global)._addedLength = (this || _global)._result.Distance + (this || _global)._totalLength;
        L.polyline([(this || _global)._clickedLatLong, (this || _global)._movingLatLong], (this || _global).options.lineStyle).addTo((this || _global)._tempLine);

        if ((this || _global)._clickCount > 1) {
          text = "<b>" + (this || _global).options.angleUnit.label + "</b>&nbsp;" + (this || _global)._result.Bearing.toFixed((this || _global).options.angleUnit.decimal) + "&nbsp;" + (this || _global).options.angleUnit.display + "<br><b>" + (this || _global).options.lengthUnit.label + "</b>&nbsp;" + (this || _global)._addedLength.toFixed((this || _global).options.lengthUnit.decimal) + "&nbsp;" + (this || _global).options.lengthUnit.display + "<br><div class=\"plus-length\">(+" + (this || _global)._result.Distance.toFixed((this || _global).options.lengthUnit.decimal) + ")</div>";
        } else {
          text = "<b>" + (this || _global).options.angleUnit.label + "</b>&nbsp;" + (this || _global)._result.Bearing.toFixed((this || _global).options.angleUnit.decimal) + "&nbsp;" + (this || _global).options.angleUnit.display + "<br><b>" + (this || _global).options.lengthUnit.label + "</b>&nbsp;" + (this || _global)._result.Distance.toFixed((this || _global).options.lengthUnit.decimal) + "&nbsp;" + (this || _global).options.lengthUnit.display;
        }

        L.circleMarker((this || _global)._movingLatLong, (this || _global).options.circleMarker).bindTooltip(text, {
          sticky: true,
          offset: L.point(0, -40),
          className: "moving-tooltip"
        }).addTo((this || _global)._tempPoint).openTooltip();
      }
    },
    _escape: function (e) {
      if (e.keyCode === 27) {
        if ((this || _global)._clickCount > 0) {
          this._closePath();
        } else {
          (this || _global)._choice = true;

          this._toggleMeasure();
        }
      }
    },
    _calculateBearingAndDistance: function () {
      var f1 = (this || _global)._clickedLatLong.lat,
          l1 = (this || _global)._clickedLatLong.lng,
          f2 = (this || _global)._movingLatLong.lat,
          l2 = (this || _global)._movingLatLong.lng;
      var toRadian = Math.PI / 180; // haversine formula
      // bearing

      var y = Math.sin((l2 - l1) * toRadian) * Math.cos(f2 * toRadian);
      var x = Math.cos(f1 * toRadian) * Math.sin(f2 * toRadian) - Math.sin(f1 * toRadian) * Math.cos(f2 * toRadian) * Math.cos((l2 - l1) * toRadian);
      var brng = Math.atan2(y, x) * (((this || _global).options.angleUnit.factor ? (this || _global).options.angleUnit.factor / 2 : 180) / Math.PI);
      brng += brng < 0 ? (this || _global).options.angleUnit.factor ? (this || _global).options.angleUnit.factor : 360 : 0; // distance

      var R = (this || _global).options.lengthUnit.factor ? 6371 * (this || _global).options.lengthUnit.factor : 6371; // kilometres

      var deltaF = (f2 - f1) * toRadian;
      var deltaL = (l2 - l1) * toRadian;
      var a = Math.sin(deltaF / 2) * Math.sin(deltaF / 2) + Math.cos(f1 * toRadian) * Math.cos(f2 * toRadian) * Math.sin(deltaL / 2) * Math.sin(deltaL / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var distance = R * c;
      (this || _global)._result = {
        Bearing: brng,
        Distance: distance
      };
    },
    _closePath: function () {
      (this || _global)._map.removeLayer((this || _global)._tempLine);

      (this || _global)._map.removeLayer((this || _global)._tempPoint);

      if ((this || _global)._clickCount <= 1) (this || _global)._map.removeLayer((this || _global)._pointLayer);
      (this || _global)._choice = false;
      L.DomEvent.on((this || _global)._container, "click", (this || _global)._toggleMeasure, this || _global);

      this._toggleMeasure();
    }
  });

  L.control.ruler = function (options) {
    return new L.Control.Ruler(options);
  };
}, window);

export default exports;